import React from 'react';

import GoogleMaps from '../google-maps/GoogleMaps';
import ContactsAccordion from './ContactsAccordion';

const ContactsContent = () => {
    return (
        <div id="maps" className="maps style-default">
            {/* <GoogleMaps /> */}
            <div style={{width: "100%"}}><iframe width="100%" height="550" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=550&amp;hl=en&amp;q=Trata%2019,%204201%20Zgornja%20Besnica+(Labor%20d.o.o.)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure distance on map</a></iframe></div>
        </div>
    );
};

export default ContactsContent;
