import React from 'react';

import WhyUsPdf from './WhyUsPdf';

const WhyUsContent = () => {
    return (
        <div className="wrapper">
            <div className="content">
                <div className="clearfix">
                    <div className="row gutter-width-lg with-pb-lg style-default">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">01</h4>
                            <p className="text-primary p-large bold">Podpora in odzivnost</p>
                            <p>Z vami smo od prevzema blaga do dostave in vam v primeru težav vedno stojimo od strani. Stavimo na oseben in profesionalen odnos.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">02</h4>
                            <p className="text-primary p-large bold">Fleksibilnost</p>
                            <p>Pri sestavljanju ponudbe vedno prisluhnemo željam in potrebam naših strank.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">03</h4>
                            <p className="text-primary p-large bold">Poštena cena</p>
                            <p>Naše storitve se obračunavajo pošteno in transparentno, ponudba se sestavi glede na vaše želje.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUsContent;
