import React, { useRef, useState } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';

const ShortCodeModalForm =()=> {
    const form = useRef();

    const [alertTimeout, setAlertTimeout] = useState('');
    const [delay, setDelay] = useState(5000);

    const submitForm = async ( e ) => {
        e.preventDefault();

        if ( document.querySelector( '#alert' ) ) {
            document.querySelector( '#alert' ).remove();
        }

        // this.setState( { isSubmitting: true } );

        // axios.post( 'https://store.adveits.com/API/form.php', this.state.values, {
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json; charset=UTF-8',
        //     },
        // } ).then( ( response ) => {
        //     if ( response.data.status === 'success' ) {
        //         this.setState( { responseMessage: this.state.successMessage } );
        //     }

        //     if ( response.data.status === 'warning' ) {
        //         this.setState( { responseMessage: this.state.warningMessage } );
        //     }

        //     if ( response.data.status === 'error' ) {
        //         this.setState( { responseMessage: this.state.errorMessage } );
        //     }

        //     this.callAlert( this.state.responseMessage, response.data.status );
        // } ).catch( ( error ) => {
        //     this.callAlert( this.state.errorMessage, 'error' );
        // } );
        emailjs.sendForm('service_758yx1q', 'template_957vxk2', form.current, 'EG2KJE-cquJiCnRb0')
        .then((result) => {
            console.log(result.text);
            if(result.status = 200) {
                callAlert("Sporočilo je bilo uspešno poslano.", 'success')
            } else {
                callAlert("Napaka pri pošiljanju sporočila. Poskusite znova.", 'warning')
            }
        }, (error) => {
            console.log(error.text);
        });
    };

    const removeAlert = () => {
        clearTimeout(alertTimeout );
        // this.setState( {
        //     alertTimeout: setTimeout( function() {
        //         var element = document.querySelector( '#alert' );
        //         element.classList.remove( 'fadeIn' );
        //         element.classList.add( 'fadeOut' );
        //         setTimeout( function() {
        //             element.remove();
        //         }, 900 );
        //     }, delay ),
        // } );
        setAlertTimeout(
            setTimeout( function() {
                var element = document.querySelector( '#alert' );
                element.classList.remove( 'fadeIn' );
                element.classList.add( 'fadeOut' );
                setTimeout( function() {
                    element.remove();
                }, 900 );
            }, delay ),
        );
    };

    const callAlert = ( message, type ) => {
        if ( ! document.querySelector( '#alert' ) ) {
            if ( type === 'success' ) {
                var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-success">' + message + '</div>';
            }

            if ( type === 'warning' ) {
                var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-warning">' + message + '</div>';
            }

            var element = document.querySelector( '.contact-form' );

            element.insertAdjacentHTML( 'beforeend', alert );

            removeAlert();
        }
    };

    // handleInputChange = ( e ) => {
    //     this.setState( {
    //         values: {
    //             ...this.state.values,
    //             [e.target.name]: e.target.value,
    //         },
    //     } );
    // };

        return (
            <form ref={form} onSubmit={submitForm} className="contact-form">
                <div className="form-group">
                    <label className="after" htmlFor="name">Ime</label>
                    <input name="name" type="text" className="form-lg" id="name" required="required" placeholder="Ime" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="lastName">Priimek</label>
                    <input name="lastName" type="text" className="form-lg" id="lastName" required="required" placeholder="Priimek" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="email">Email</label>
                    <input name="user_email" type="email" className="form-lg" id="email" required="required" placeholder="Email" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="company">Podjetje</label>
                    <input name="company" type="text" className="form-lg" id="company" required="required" placeholder="Podjetje" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="phone">Telefon</label>
                    <input name="phone" type="text" className="form-lg" id="phone" required="required" placeholder="Telefon" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="message">Sporočilo</label>
                    <textarea name="message" className="form-lg" id="message" required="required" placeholder="Tukaj napišite sporočilo"></textarea>
                </div>

                <button type="submit" className="btn btn-secondary transform-scale-h">Pošlji</button>
            </form>
        );
};

export default ShortCodeModalForm;