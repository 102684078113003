import React from 'react';

const ClientsList = () => {
    return (
        <div className="client-items clearfix">
            <div className="client-item">
                <div className="client-body">
                    <a href='https://www.shell.si/' target="_blank">
                        <img src="assets/img/demo/shell.jpg" alt="Shell" />
                    </a>
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <a href='https://www.petrol.si/' target="_blank">
                        <img src="assets/img/demo/petrol.svg" alt="Petrol" />
                    </a>
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <a href='https://www.lthcastings.com/' target="_blank">
                        <img src="assets/img/demo/lhtcastings.jpg" alt="Lht Castings" />
                    </a>
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <a href='https://www.silganmp.com/home/index.php/en/slovenia.html' target="_blank">
                        <img src="assets/img/demo/silgan.png" alt="Silgan" />
                    </a>
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <a href='https://vija-mbt.de/' target="_blank">
                        <img src="assets/img/demo/vija.png" alt="Vija" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ClientsList;
