import React from 'react';

import FooterMenu from '../footer/FooterMenu';

const FooterWidget = () => {
    return (
      <div className="footer-widgets">
          <div className="footer-widget-area d-flex flex-wrap">
              <div className="widget">
                  <h5 className="widget-title">Vrste prevozov:</h5>
                    <ul>
                        <li style={{color:"wihte"}}>volumenski prevozi,</li>
                        <li>nakladi kompletnih pošiljk,</li> 
                        <li>zbirni prevozi.</li>
                    </ul>
              </div>

              <div className="widget">
                  <h5 className="widget-title">Meni</h5>

                  <FooterMenu />
              </div>

              <div className="widget">
                  <h5 className="widget-title">Kontakt</h5>

                  <p>Trata 19, 4201 Zgornja Besnica</p>

                  <p>labor.transport@siol.net</p>

                  <p>040 228 719</p>
              </div>
          </div>
      </div>
    );
};

export default FooterWidget;
