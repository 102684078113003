import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Fade from '@material-ui/core/Fade';

class HomeHeaderMenuPrimary extends Component {
    constructor() {
        super();
        this.state = {
            over: false,
            pushed: false
        };

        this.scrollEvent     = this.scrollEvent.bind( this );
        this.set             = this.set.bind( this );
        this.handleOverEnter = this.handleOverEnter.bind( this );
        this.handleOverLeave = this.handleOverLeave.bind( this );
    };

    handleOverEnter() {
        this.setState( { over: true } );
    };

    handleOverLeave() {
        this.setState( { over: false } );
    };

    componentDidMount() {
        window.addEventListener( 'scroll', this.scrollEvent, { passive: true } );
    };

    componentWillUnmount() {
        window.removeEventListener( 'scroll', this.scrollEvent, { passive: true } );
    };

    removeActive() {
        if ( document.querySelector( '.active' ) ) {
            let actives = document.querySelector( '.active' );

            if ( actives !== null ) {
                actives.classList.remove( "active" );
            }
        }
    };

    scrollEvent() {
        let offset = 631;
        const offsets = [];
        const link_names = [];
        
        offsets.push( document.getElementById( "home" ).offsetTop );
        offsets.push( document.getElementById( "services" ).offsetTop );
        offsets.push( document.getElementById( "about-us" ).offsetTop );
        // offsets.push( document.getElementById( "why-us" ).offsetTop );
        offsets.push( document.getElementById( "gallery" ).offsetTop );
        offsets.push( document.getElementById( "reviews" ).offsetTop );
        offsets.push( document.getElementById( "clients" ).offsetTop );
        // offsets.push( document.getElementById( "news" ).offsetTop );
        offsets.push( document.getElementById( "footer" ).offsetTop );

        link_names.push( document.getElementById( "nav-home" ) );
        link_names.push( document.getElementById( "nav-services" ) );
        link_names.push( document.getElementById( "nav-about-us" ) );
        // link_names.push( document.getElementById( "nav-why-us" ) );
        link_names.push( document.getElementById( "nav-gallery" ) );
        link_names.push( document.getElementById( "nav-reviews" ) );
        link_names.push( document.getElementById( "nav-clients" ) );
        // link_names.push( document.getElementById( "nav-news" ) );
        link_names.push( document.getElementById( "nav-footer" ) );

        if ( ! this.state.pushed ) {
            if ( window.scrollY < offsets[0] ) {
                this.removeActive();
            }

            for (let i = 0; i < offsets.length; i++) {
                if ( window.scrollY + offset > offsets[i] ) {
                    this.removeActive();
                    link_names[i].classList.add( 'active' );
                }  
            }
        }
    };

    handleClick( e ) {
        this.setState( { pushed: true } )
        this.removeActive();

        let active = e.target;
        active.parentNode.classList.add( "active" );

        setTimeout( this.set, 800 );
    };

    set() {
        this.setState( { pushed: false } )
    };

    render() {
        const { over } = this.state;

        return (
            <>
            <div className="header-left align-self-center" id="nav-home">
                <Link to="home" title="Labor" href="#home" onClick={ ( e ) => this.handleClick( e ) }><b>LABOR</b></Link>
            </div>


            <div className="header-center align-self-center">
                <div className="menu d-flex justify-content-center">
                    <nav className="menu-primary">
                        <ul className="nav">
                            <li className="nav-item" id="nav-services">
                                <Link to="services" title="Storitve" href="#services" onClick={ ( e ) => this.handleClick( e ) }>Storitve</Link>
                            </li>

                            <li className="nav-item" id="nav-about-us">
                                <Link to="about-us" title="O podjetju" onClick={ ( e ) => this.handleClick( e ) } href="#about-us">O podjetju</Link>
                            </li>

                            {/* <li className="nav-item" id="nav-why-us">
                                <Link to="why-us" title="Why us" onClick={ ( e ) => this.handleClick( e ) } href="#why-us">Why us</Link>
                            </li> */}

                            <li className="nav-item" id="nav-gallery">
                                <Link to="gallery" title="Galerija" onClick={ ( e ) => this.handleClick( e ) } href="#gallery">Galerija</Link>
                            </li>

                            {/* <li onMouseOver={ this.handleOverEnter } onMouseOut={ this.handleOverLeave } id="nav-gallery" className={ "nav-item nav-item-has-children dropdown-hover " + ( this.state.over ? 'show' : '' ) }>
                                <Link to="gallery" title="Gallery" onClick={ ( e ) => this.handleClick( e ) } href="#gallery">Galerija</Link>

                                <span className="dropdown-toggle dropdown-custom-icon">
                                    <span className="dropdown-icon">
                                        <i className="fas fa-caret-down"></i>
                                    </span>
                                </span>

                                <Fade in={ over } timeout={ 400 }>
                                    <ul className="dropdown-menu" style={ { display: "block" } }>
                                        <li>
                                            <a className="dropdown-item" title="Truck Logistics" href={ process.env.PUBLIC_URL + '/gallery-inside' }>Truck Logistics</a>
                                        </li>

                                        <li>
                                            <a className="dropdown-item" title="Ship Logistics" href={ process.env.PUBLIC_URL + '/gallery-inside' }>Ship Logistics</a>
                                        </li>

                                        <li>
                                            <a className="dropdown-item" title="Storage Logistics" href={ process.env.PUBLIC_URL + '/gallery-inside' }>Storage Logistics</a>
                                        </li>

                                        <li>
                                            <a className="dropdown-item" title="Plain Logistics" href={ process.env.PUBLIC_URL + '/gallery-inside' }>Plane Logistics</a>
                                        </li>
                                    </ul>
                                </Fade>
                            </li> */}

                            <li className="nav-item" id="nav-reviews">
                                <Link to="reviews" title="Reference" onClick={ ( e ) => this.handleClick( e ) } href="#reviews">Reference</Link>
                            </li>

                            <li className="nav-item" id="nav-clients">
                                <Link to="clients" title="Naše stranke" onClick={ ( e ) => this.handleClick( e ) } href="#clients">Naše stranke</Link>
                            </li>

                            {/* <li className="nav-item" id="nav-news">
                                <Link to="news" title="News" onClick={ ( e ) => this.handleClick( e ) } href="#news">News</Link>
                            </li> */}

                            <li className="nav-item" id="nav-footer">
                                <Link to="footer" title="Kontakt" onClick={ ( e ) => this.handleClick( e ) } href="#footer">Kontakt</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            </>
        );
    };
};

export default HomeHeaderMenuPrimary;
