import React from 'react';

const Copyright = () => {
    return (
      <div className="copyright">
          <p>2023 LABOR, trgovina in posredništvo d.o.o.</p>
      </div>
    );
};

export default Copyright;
