import React from 'react';

// const LinkTitle = () => {
//     return (                    
//         <a title="O podjetju" className="transform-scale-h" href={ `${ process.env.PUBLIC_URL + "/about-us" }` }>
//             O podjetju<i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
//         </a>
//     );
// };
  
// const TextTitle = () => {
//     return ( <div title="O podjetju">O podjetju</div> );
// };

// const AboutUsTitle = ( props ) => {

// const isHomepage = props.isHomepage;

//     if ( isHomepage ) {
//         return <LinkTitle />;
//     }
//         return <TextTitle />;
// };

const AboutUsPrimary = ( props ) => {
    return (
        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="title">
                <h2 className="transform-scale-h" title='O podjetju'>
                    O podjetju
                    {/* <AboutUsTitle isHomepage={ props.isHomepage } /> */}
                </h2>
            </div>

            <div className="text">
                <p>Podjetje Labor d.o.o. je transportno podjetje, ki je bilo ustanovljeno leta 2013 kot družinsko podjetje. Trdo delo in vztrajnost sta poskrbela, da je podjetje kmalu preraslo okvirje lokalnega okolja in svojo mrežo razširilo po celotnem evropskem trgu.</p>
                <p>Sedež podjetja je v Zgornji Besnici.</p>
            </div>

            <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default">
                <div className="list-group-item">
                    <h4 className="text-secondary">10 Let</h4>
                    <p>Izkušenj</p>
                </div>

                <div className="list-group-item">
                    <h4 className="text-secondary">5x</h4>
                    <p>Kamion</p>
                </div>

                <div className="list-group-item">
                    <h4 className="text-secondary">2x</h4>
                    <p>Kombi</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPrimary;
